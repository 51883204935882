@font-face {
  font-family: 'IBM VGA 8';
  src: url('../fonts/Inconsolata-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .App-header {
  background-color: #1c1c1c;
}

.dark-mode .App-link {
  color: #61dafb;
}
