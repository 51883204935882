[dir="rtl"] {
  text-align: right;
}

[dir="rtl"] .header-content,
[dir="rtl"] .nav {
  flex-direction: row-reverse;
}

[dir="rtl"] .list {
  padding-right: 20px;
  padding-left: 0;
}

[dir="rtl"] input,
[dir="rtl"] textarea {
  text-align: right;
}

[dir="rtl"] .post-container,
[dir="rtl"] .project-container {
  text-align: right;
}